import { ReactTyped } from 'react-typed';
import ContactButton from './Contact'

const Hero = () => {
    return (
      <div>
        <div className="container h-screen mx-auto flex items-center justify-start">
        <div className="py-20 text-left lg:w-4/5">
          <h1 className="font-bold tracking-tight text-gray-200">
            <div>Hello!</div> I'm <span className="name-tag whitespace-nowrap" aria-live="polite" aria-atomic="true">
              <ReactTyped
              strings={["Christina Luna"]}
              typeSpeed={80}
              backSpeed={80}
              />
            </span>
          </h1>
          <p className="text-gray-200 text-xl md:w-4/5">
            A Front End Software Engineer passionate about crafting intuitive and visually engaging web experiences. Currently, leading the front-end architecture at <strong className="text-blue-200">Stanley Black and Decker</strong>.
          </p>
          <ContactButton />
        </div>
      </div>
    </div>
    );
  };
  
  export default Hero;
