import React, { useState } from 'react';

const VerticalTabs = ({ tabsData }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="flex">
      <div className="hidden md:block w-1/4 border-r-2">
        {tabsData.map((tab, index) => (
          <button
            key={index}
            className={`w-full text-left p-4 hover:border-l-2 hover:border-blue-400 text-white ease-in duration-300 ${
              activeTab === index ? 'border-l-2 border-blue-400' : ''
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.company}
          </button>
        ))}
      </div>
      <div className="hidden md:block text-left w-3/4 px-4">
        <div className="p-4">
          <h4 className="text-gray-200">{tabsData[activeTab].company}</h4>
          <h2 className="text-lg font-semibold text-gray-400">{tabsData[activeTab].title} | {tabsData[activeTab].duration}</h2>
          <p className="text-gray-300 mt-2 mb-0">{tabsData[activeTab].description}</p>
        </div>
      </div>
      <div className='md:hidden'>
      {tabsData.map((tab, index) => (
        <div className="text-left mb-8">
          <h4 className="text-gray-200">{tab.company}</h4>
          <p className="text-lg font-semibold text-gray-400 m-0">{tab.title}</p>
          <p className='text-base font-semibold text-gray-400'>{tab.duration}</p>
          <p className="text-gray-300 mt-2 mb-0">{tab.description}</p>
        </div>
      ))}
      </div>
    </div>
  );
};

export default VerticalTabs;
