
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './components/Hero';
import WorkExperience from './components/Experience';
import Work from './components/Work';
import About from './components/About';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <div className="App bg-gradient-to-r from-blue-900 to-blue-950 h-screen overflow-auto">
      <Navigation/>
      <div className='h-screen'>
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/work-experience" element={<WorkExperience />} />
          <Route path="/projects" element={<Work />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
      </div>
    </Router>
  );
}

export default App;
