import React, { useState } from 'react';

const ContactButton = () => {
    const [honeypot, setHoneypot] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        if (honeypot) {
            alert('Spam detected!');
            return;
        }

        window.location.href = 'mailto:lunachristinam@gmail.com';
    };

    return (
        <form onSubmit={handleSubmit} className="inline-block">
            <input
                type="text"
                name="honeypot"
                style={{ display: 'none' }}
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
            />
            <button type="submit" className='my-6 inline-block border border-blue-200 px-6 py-3 text-blue-200 text-lg font-bold hover:bg-blue-200 hover:text-blue-800 transition-all duration:300 ease-in hover:shadow-lg'>
                Contact Me
            </button>
        </form>
    );
};

export default ContactButton;
