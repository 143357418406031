import React from "react";

function WorkCard(props) {
    // const tags = props.tags || ""; 
    // const skills = tags.split(',').map(tag => tag.trim()).filter(tag => tag);

    return (
        <a href={props.projectUrl} target="_blank" rel="noreferrer">
            <div className="group relative">
                <div className="overflow-hidden shadow-lg">
                <div
                className={`bg-cover bg-${props.imagePosition} h-[200px] md:h-[400px] transition-transform duration-300 mix-blend-lighten`}
                style={{ backgroundImage: `url(${props.image})` }}
                ></div>
                </div>
                <div className="hidden md:block absolute z-10 inset-0 bg-blue-800 opacity-0 will-transform group-hover:opacity-95 transition-all duration-300 transform translate-x-0 translate-y-0 group-hover:translate-x-3 group-hover:translate-y-3 flex items-end p-4">
                    <div className="text-white transform -translate-y-10 group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                        <h3 className="text-xl">
                        {props.title}
                        </h3>
                        <p>{props.description}</p>
                    </div>
                </div>
                <div className="p-4 bg-blue-800 md:hidden text-white">
                    <h3 className="text-xl">
                    {props.title}
                    </h3>
                    <p>{props.description}</p>
                </div>
            </div>
        </a>
    );
}

export default WorkCard;
