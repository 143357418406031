import React, { useState, useRef, useEffect } from "react";
import { FaLinkedin, FaGithub, FaCodepen, FaBars } from 'react-icons/fa';
import Christina from '../images/christina-square.jpg';

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState(0);

  useEffect(() => {
    if (menuRef.current) {
      setMenuWidth(menuRef.current.getBoundingClientRect().width);
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <header>
      {/* Mobile Navigation Button */}
      <div className="absolute top-4 right-4 md:hidden">
        <button onClick={toggleMenu} className="focus:outline-none">
          <FaBars className="block w-8 text-3xl text-blue-200" />
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-75 z-50 transition-opacity duration-300 ease-in-out ${isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      >
        <div
          ref={menuRef}
          className={`fixed top-0 right-0 h-full w-3/4 bg-blue-900 text-black transition-transform duration-300 ease-in-out transform ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
          style={{ transform: isMenuOpen ? 'translateX(0)' : `translateX(${menuWidth}px)` }}
        >
          <button onClick={toggleMenu} className="absolute top-4 right-4 text-4xl text-blue-200">
            &times;
          </button>
          <nav className="flex flex-col items-center h-full space-y-8 text-blue-200">
            <div className="flex flex-col items-center justify-center w-full mt-20 mb-10">
              <a href="/">
                <img
                  src={Christina}
                  alt="Christina Luna"
                  className="object-cover rounded-full h-[100px] w-[100px] mb-4 border-2 border-blue-200"
                />
              </a>
              <p className="text-lg text-white mb-0">Christina Luna</p>
              <p className="text-sm text-white mb-0">Front End Software Engineer</p>
            </div>
            <div className="flex flex-col items-center space-y-6">
              <a href="/" className="text-normal hover:underline">Home</a>
              <a href="/projects" className="text-normal hover:underline">Work</a>
              <a href="/work-experience" className="text-normal hover:underline">Experience</a>
              <a href="/about" className="text-normal hover:underline">About</a>
            </div>
            <div className="flex space-x-6 py-10">
              <a href="https://www.linkedin.com/in/christina-luna/" target="_blank" rel="noopener noreferrer" className="text-3xl m-2 hover:text-blue-300">
                <FaLinkedin />
              </a>
              <a href="https://github.com/christinaluna" target="_blank" rel="noopener noreferrer" className="text-3xl m-2 hover:text-blue-300">
                <FaGithub />
              </a>
              <a href="https://codepen.io/miss_cluna" target="_blank" rel="noopener noreferrer" className="text-3xl m-2 hover:text-blue-300">
                <FaCodepen />
              </a>
            </div>
          </nav>
        </div>
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden absolute top-4 left-4 md:flex space-x-6 text-lg text-gray-200">
        <a href="/" className="transition-transform transform hover:text-blue-300 hover:-translate-y-1">Home</a>
        <a href="/projects" className="transition-transform transform hover:text-blue-300 hover:-translate-y-1">Work</a>
        <a href="/work-experience" className="transition-transform transform hover:text-blue-300 hover:-translate-y-1">Experience</a>
        <a href="/about" className="transition-transform transform hover:text-blue-300 hover:-translate-y-1">About</a>
      </nav>

      {/* Profile and Social Links for Desktop */}
      <div className="hidden md:grid fixed top-4 right-4 place-content-center place-items-center">
        <a href="/">
          <img
            src={Christina}
            alt="Christina Luna"
            className="object-cover rounded-full h-[60px] w-[60px] mb-8 border-2 border-blue-200"
          />
        </a>
          <a href="https://www.linkedin.com/in/christina-luna/" target="_blank" rel="noopener noreferrer" className="text-3xl block text-gray-200 m-2 hover:text-blue-300 transition-transform transform hover:-translate-x-2 will-change-transform ease-in duration-300">
            <FaLinkedin />
          </a>
          <a href="https://github.com/christinaluna" target="_blank" rel="noopener noreferrer" className="text-3xl block text-gray-200 m-2 hover:text-blue-300 transition-transform transform hover:-translate-x-2 will-change-transform ease-in duration-300">
            <FaGithub />
          </a>
          <a href="https://codepen.io/miss_cluna" target="_blank" rel="noopener noreferrer" className="text-3xl block text-gray-200 m-2 hover:text-blue-300 transition-transform transform hover:-translate-x-2 will-change-transform ease-in duration-300">
            <FaCodepen />
          </a>
      </div>
    </header>
  );
}

export default Navigation;
