const Footer = () => {
    return (
      <footer className="absolute hidden md:block bottom-0 bg-blue-950 text-gray-400 py-4 inset-x-0">
        <div className="container mx-auto text-left">
          <p className="text-sm mb-0">
            Built in <strong className="text-blue-400">Visual Studio Code</strong> using <strong className="text-blue-400">React</strong> and <strong className="text-blue-400">TailwindCSS</strong> and deployed with  <strong className="text-blue-400">Firebase</strong>.
          </p>
        </div>
      </footer>
    );
  };
  
  export default Footer;
  