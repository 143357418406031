import React from "react";
import { Helmet } from "react-helmet";

function About() {
    return (
        <>
            <Helmet>
                <title>About Me - Front End Software Engineer</title>
                <meta name="description" content="Discover the professional journey of a Front End Software Engineer based in Phoenix, AZ, with over 10 years of experience in various industries, including pharmaceuticals, finance, and education." />
                <meta name="keywords" content="Front End Developer, Software Engineer, React, Vue, TypeScript, JavaScript, HTML, CSS, Phoenix AZ, professional experience, web development, project management, outdoor enthusiast, travel, photography" />
                <meta name="author" content="Christina Luna" />
            </Helmet>
            <div className="py-20 md:py-40">
                <div className="container text-gray-200 text-left">
                    <h1 className="font-bold text-3xl md:text-5xl tracking-normal text-blue-200">About me</h1>
                    <div className="md:grid md:grid-cols-2 md:grid-flow-col md:gap-4 my-10">     
                        <div>
                            <p>
                                I'm a Front End Software Engineer based in Phoenix, AZ, with over 10 years of experience in the industry. Throughout my career, I've had the opportunity to work on a diverse range of projects across sectors including pharmaceuticals, finance, education, and construction tools. I'm well-versed in a variety of technologies such as Drupal, Adobe Experience Manager, Contentful, React, Vue, TypeScript, JavaScript, HTML, CSS, Vercel, and Firebase.
                            </p>
                            <p>
                                I'm passionate about continuous improvement and enjoy collaborating with others to create innovative solutions. My leadership experience has equipped me to effectively manage projects and ensure our front-end architecture meets high standards.
                            </p>
                        </div>
                        <div>
                            <p>
                                When I'm not working, you can find me exploring the great outdoors with my family. I love to travel and experience new places, whether it's hiking in the mountains or relaxing by the beach, capturing memories through photography or working on DIY projects in my garage.
                            </p>
                            <p>Let's connect and explore new opportunities together!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;
